import React, { Fragment } from 'react';
import Hero from '../components/Hero';
import Cards from '../components/Cards';
import Tools from '../components/Tools';
import Experience from '../components/AcademicFormation';

export default function Main() {
  return (
    <Fragment>
      <Hero />
      <div className="container my-5">
        <Tools />
        <Cards />
        <Experience />
      </div>
    </Fragment>
  )
}
