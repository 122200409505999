import {
  FaGithub,
  FaLinkedin,
  FaYoutube,
  FaDribbble,
  FaMediumM,
  FaInstagram,
  FaEnvelope
} from 'react-icons/fa';
import { me } from './AboutMe';

export const footerLinks = [
  {
    icon: <FaLinkedin />,
    name: 'LinkedIn',
    label: 'LinkedIn',
    link: me.linkedin
  },
  {
    icon: <FaGithub />,
    name: 'GitHub',
    label: 'GitHub',
    link: me.github
  },
  {
    icon: <FaMediumM />,
    name: 'Medium',
    label: 'Medium',
    link: me.medium
  },
  {
    icon: <FaInstagram />,
    name: 'Instagram',
    label: 'Instagram',
    link: me.instagram
  },
  {
    icon: <FaYoutube />,
    name: 'YouTube',
    label: 'YouTube',
    link: me.youtube
  },
  {
    icon: <FaDribbble />,
    name: 'Dribbble',
    label: 'Dribbble',
    link: me.dribbble
  },
  {
    icon: <FaEnvelope />,
    name: 'Mail',
    label: 'Mail',
    link: me.email
  }
];
