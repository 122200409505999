import React from 'react';
import { toolsData } from '../data/CardsData';

export default function Cards() {
  return (
    <div className="container mb-5">
      <h2 className={ 'text-primary fw-bold mb-4' }>My Own Developer Kit</h2>
      <div className="row">
        {
          toolsData.map((item, key) => (
            <div className={ 'col-3 col-md-2 col-lg-1 p-1' } key={ key }>
              <div className="d-flex flex-column text-center">
                <i className={ 'display-3' }>{ item.icon }</i>
                <span className={ 'fw-light mt-1' }>{ item.name }</span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
