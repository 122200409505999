import React from 'react';
import ProjectCards from '../components/ProjectCards';
import { me } from '../data/AboutMe';

export default function Portfolio() {
  return (
    <section id="portfolio" className={ 'container my-5 fw-light' }>
      <div className={ 'row mb-3' }>
        <div className="col-12">
          <h1 className={ 'text-primary fw-bold pt-4 mb-3' }>Portfolio</h1>
          <h2 className={ 'text-secondary' }>Previous Experiences</h2>
          <p>Here are some of the projects I helped to manage, build or design in the past.</p>
          <p>Feel free to explore my <a className={ 'fw-bold link-primary text-decoration-none' } href={ me.github } target="_blank" rel="noreferrer">GitHub</a> account and check out the latest projects I've been working on! 🔥🚀</p>
        </div>
      </div>
      <ProjectCards />
    </section>
  )
}
