import React from 'react';
import { FaGhost, FaArrowLeft } from 'react-icons/fa'

export default function ErrorPage() {
  return (
    <div id="error" className={ 'container my-5' }>
      <div className="row justify-content-center text-center">
        <div className="col-12 mt-5">
          <i className="text-dark"><FaGhost className={ 'my-4' } size={100} /></i>
          <h1 className={ 'text-primary fw-bold' }>It's a ghost page!</h1>
          <p className={'lead'}>The page you're looking for hasn't been published... yet.</p>
          <a href="/" className="btn btn-primary rounded-pill text-light">
            <FaArrowLeft className={ 'me-1' } /> Back to site
          </a>
        </div>
      </div>
    </div>
  )
}
