import React from 'react';
import { me } from '../data/AboutMe';
import { Featurette } from '../components/Featurette';
import { Heading1 } from '../components/Headings';

export default function About() {
  return (
    <section id="about" className={ 'container my-5' }>
      <Heading1 title="About Me" />

      <Featurette.Container>
        <div className="col-md-7 mb-4 mb-md-0">
          <Featurette.Heading>
            Hi, I'm <span className={ 'text-muted' }>{ me.name }</span>,
            a graduated <span className="fw-normal">Software Engineer</span>
            from <span className="bold">{ me.location }</span>. 👋🏽😉
          </Featurette.Heading>
        </div>
        <div className="col-md-5 mb-3 mb-lg-0">
          <img
            src={  me.picture }
            alt={ me.name }
            className="d-block mx-lg-auto img-fluid shadow-lg rounded-3"
            width="500"
            height="500"
            loading="lazy"
          />
        </div>
      </Featurette.Container>

      <Featurette.Divider />

      <Featurette.Container>
        <div className="col-md-5">
          <img
            src={ me.gallery['image_02'] }
            alt={ me.name }
            className="d-block mx-lg-auto img-fluid shadow-lg rounded-3"
            width="500"
            height="500"
            loading="lazy"
          />
        </div>

        <div className="col-md-7 mt-5 mt-md-0">
          <Featurette.Heading>
            I'm constantly learning new things.
          </Featurette.Heading>
          <Featurette.Lead>
            <p>
              Whether is about my professional career or self development,
              or any other of my interests: finance, health, languages,
              books, films &amp; music. 🐢
            </p>
          </Featurette.Lead>
        </div>
      </Featurette.Container>

      <Featurette.Divider />

      <Featurette.Container>
        <div className="col-12">
          <Featurette.Heading>
            Team leader and mentor of my peers at college.
          </Featurette.Heading>
          <Featurette.Lead>
            <p>
              When I was at college, my teachers always motivaded me
              to connect with my peers, and I enrolled myself in
              extracurricular activities, like the PASA Program.
              </p>
            <p>PASA, enabled me to teach different topics from Basics
              of Computer Science to Object Oriented Programming using
              languages like JavaScript, PHP, Python, and others.</p>
            <p>I always pushed myself to be a <span className="bold">team leader</span>
            in most of my team projects, and I did my best being a collaborator
            in other teams as well. Together, we excelled in many scholar projects.
            I can say my greatest achievement has been recognizing everyone's
            habilities and getting them involved. 🙌🏽</p>
          </Featurette.Lead>
        </div>
      </Featurette.Container>

      <Featurette.Divider />

      <div className="container">
        <div className="row featurette">
          <div className="col-12">
            <img src={ me.gallery['image_03'] } className={ 'img-fluid shadow-lg rounded-3' } alt="" />
          </div>
          <div className="col-12 mt-5">
            <Featurette.Heading>
            I enjoy attending tech events.
            </Featurette.Heading>
            <Featurette.Lead>
              <p>
                Learning from others and being able to meet different people
                in the tech industry outside my workplace has allowed me
                to stay focus and rethink my objectives when I needed to.
              </p>
            </Featurette.Lead>
          </div>
        </div>
      </div>
    </section>
  )
}
