import firebase from 'firebase/app';
import 'firebase/analytics';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCjW_366Ya4Du85AiTy-Klb37rLVU_HBC4",
  authDomain: "nadiemedicejose.firebaseapp.com",
  projectId: "nadiemedicejose",
  storageBucket: "nadiemedicejose.appspot.com",
  messagingSenderId: "396163774000",
  appId: "1:396163774000:web:593c77c2b03125266a1ff5",
  measurementId: "G-T95J5HTH14"
};

firebase.initializeApp(firebaseConfig);

export default firebase;