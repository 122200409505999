import React from 'react'
import AppRouter from './routes/AppRouter'
import './styles/App.scss'

export default function App() {
  return (
    <div className="App d-flex flex-column min-vh-100">
      <AppRouter />
    </div>
  )
}
