import { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import firebase from '../services/firebase'
import { me } from '../data/AboutMe'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Main from '../pages/Main'

import About from "../pages/About";
import Portfolio from "../pages/Portfolio";
import Contact from "../pages/Contact";
import SuccessPage from '../pages/Success'
import ErrorPage from '../pages/ErrorPage'

const Resume = () => window.location = me.resume;

const RoutesListener = () => {
  const location = useLocation();

  useEffect(() => {
    firebase.analytics().setCurrentScreen(location.pathname);
  }, [location])

  return <></>
}

export default function AppRouter() {
  return (
    <Router>
      <Navbar />
      <RoutesListener />
      <div id="content">
        <Switch>
          <Route exact path="/about" component={About} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/thankyou" component={SuccessPage} />
          <Route exact path="/hello" component={Contact} />
          <Route exact path="/resume.pdf" render={Resume} />
          <Route exact path="/" component={Main} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </div>
      <Footer />
    </Router>
  )
}
