import React from 'react';
import Card from './Card';
import { cardsData } from '../data/CardsData';

export default function Cards() {
  return (
    <div className="row g-3">
      {
        cardsData.map((item, key) => (
          <div className="col-12 col-md-6 col-lg-4" key={ key }>
            <Card {...item} />
          </div>
        ))
      }
    </div>
  )
}
