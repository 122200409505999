import React from 'react'
import { NavLink } from 'react-router-dom'
import { me } from '../data/AboutMe'
import { analyticsEvent } from '../services/firebase/analytics'

export default function Navbar() {
  return (
    <nav id="main-nav" className={ 'navbar navbar-expand-lg navbar-light bg-white fixed-top border border-light border-bottom-1' }>
      <div className="container">
        <NavLink className="navbar-brand text-primary fw-bold" to="/">{ me.name }</NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/about">About Me</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/portfolio">Portfolio</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to={ me.resume } loading="lazy" download={ true } onClick={() => {
                analyticsEvent('Someone downloaded my resume');
              }}>Resume</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
