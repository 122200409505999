import React from 'react';
import { footerLinks } from '../data/FooterIcons';
import { me } from '../data/AboutMe';

export default function Footer() {
  return (
    <footer className="footer mt-auto py-2 bg-primary text-light">
      <div className="container">
        <div className="row text-center align-items-center justify-content-between">
          <div className="col-12 col-md-6 text-md-start">
            <a className="fw-bold link-light text-opacity-75 text-decoration-none" href="/">{ me.name }</a>. Copyright &copy; 2023.
          </div>
          <div className="col-12 col-md-6 d-inline-flex align-items-center justify-content-center justify-content-md-end">
            {footerLinks?.map(item => (
                <a className="nav-link link-light p-2" href={ item.link } target="_blank" rel="noreferrer" aria-label={ item.label } key={ item.name }>
                  <i>{ item.icon }</i>
                </a>
              ))}
          </div>
        </div>
      </div>
    </footer>
  )
}
