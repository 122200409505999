import {
  FaMapSigns, FaLaptopCode, FaPencilAlt, FaNodeJs,
  FaDatabase, FaPhp, FaReact, FaAngular, FaLaravel,
  FaJsSquare, FaHtml5, FaFigma, FaGithub
} from 'react-icons/fa'
import { IoLogoIonic } from 'react-icons/io5'
import { BsFillBootstrapFill } from 'react-icons/bs'

export const toolsData = [
  {
    name: 'Laravel',
    icon: <FaLaravel />,
  },
  {
    name: 'PHP',
    icon: <FaPhp />,
  },
  {
    name: 'SQL',
    icon: <FaDatabase />,
  },
  {
    name: 'JavaScript',
    icon: <FaJsSquare />,
  },
  {
    name: 'HTML',
    icon: <FaHtml5 />,
  },
  {
    name: 'React',
    icon: <FaReact />,
  },
  {
    name: 'Node.js',
    icon: <FaNodeJs />,
  },
  {
    name: 'Angular',
    icon: <FaAngular />,
  },
  {
    name: 'Ionic',
    icon: <IoLogoIonic />,
  },
  {
    name: 'Bootstrap',
    icon: <BsFillBootstrapFill />,
  },
  {
    name: 'Figma',
    icon: <FaFigma />,
  },
  {
    name: 'GitHub',
    icon: <FaGithub />,
  },
]

export const cardsData = [
  {
    icon: <FaLaptopCode />,
    header: "Software Developer",
    desc: "I have experience building Desktop, Mobile and Web apps and connecting them with databases.",
    h2: "Languages & Frameworks",
    desc2: ['JavaScript', 'React', 'HTML', 'CSS', 'SASS', 'jQuery', 'Bootstrap', 'Redux', 'Python', 'Django', 'PHP', 'Java', 'C#', 'Kotlin', 'Dart'],
    h3: "Databases",
    desc3: ['PostgreSQL', 'MySQL', 'SQL Server'],
  },
  {
    icon: <FaMapSigns />,
    header: "Mentor",
    desc: 'I genuinely care about people, and love helping fellow developers or team members work on their craft.',
    desc2: [],
    h3: "Tools",
    desc3: ['Trello', 'Slack', 'Notion', 'GitHub'],
  },
  {
    icon: <FaPencilAlt />,
    header: "Designer",
    desc: "Designed and provided mockups and prototyping models for development teams.",
    h2: "What I Enjoy",
    desc2: ['Desktop', 'Mobile', 'Web'],
    h3: "Tools",
    desc3: ['Figma', 'Sketch', 'Marvel'],
  }
]
