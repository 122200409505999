import React from 'react';
import ProjectCard from './ProjectCard';
import { projectsData } from '../data/ProjectsData';

export default function ProjectCards() {
  return (
    <div className="row g-3">
      {
        projectsData.map(item => (
          <div className={ 'col col-12 col-md-6 col-lg-4 col-xxl-3' } key={item.projectName}>
            <ProjectCard {...item} />
          </div>
        ))
      }
    </div>
  )
}
