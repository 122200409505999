import randomQuoteMachine from './random-quote-machine.png'
import markdownPreviewer from './markdown-previewer.png'
import drumMachine from './drum-machine.png'
import calculator from './calculator.png'
import clock from './25-5-clock.png'
import ecommunity from './ecommunity.png'
import dentalOffice from './dental-office.png'
import school from './school.png'
import loanSimulator from './loan-simulator.png'
import reparacionesHmo from './reparaciones-hmo.png'
import uesStore from './ues-store.png'
import headerparser from './header-parser.png'
import urlshortener from './url-shortener.png'
import filemetadata from './file-metadata.png'
import timestamp from './timestamp.png'
import lanix from './lanix-ecommerce.png'

export const images = {
  randomQuoteMachine,
  markdownPreviewer,
  drumMachine,
  calculator,
  clock,
  ecommunity,
  dentalOffice,
  school,
  loanSimulator,
  reparacionesHmo,
  uesStore,
  timestamp,
  filemetadata,
  headerparser,
  urlshortener,
  lanix,
};
