import selfie from '../assets/selfie.jpg';
import { gallery } from '../assets/gallery';

export const me = {
  name: 'Jose Estrada',
  age: 27,
  email: 'mailto:nadiemedicejose@icloud.com',
  position: 'Software Engineer',
  college: 'Universidad Estatal de Sonora',
  workplace: 'Lanix Internacional',
  location: 'Hermosillo, Sonora',
  picture: selfie,
  gallery: gallery,
  tagline: `A passionate Software Engineer who loves films and building apps. I use my social platforms to document my journey. 🔥🚀`,
  resume: "/resume.pdf",

  // Social media
  linkedin: 'https://www.linkedin.com/in/nadiemedicejose/',
  github: 'https://github.com/nadiemedicejose',
  medium: 'https://nadiemedicejose.medium.com/',
  instagram: 'https://www.instagram.com/nadiemedicejose/',
  twitter: 'https://www.twitter.com/nadiemedicejose',
  youtube: 'https://www.youtube.com/channel/UChkqwv26x6n2hY2mm51LrHA',
  dribbble: 'https://dribbble.com/nadiemedicejose'
};
