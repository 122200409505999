import React from 'react';
import { FaCheckCircle, FaUndoAlt } from 'react-icons/fa';

export default function SuccessPage() {
  return (
    <div id="success" className={ 'container my-5' }>
      <div className="row justify-content-center text-center">
        <div className="col-12 mt-5">
          <i className="text-success"><FaCheckCircle className={ 'my-4' } size={100} /></i>
          <h1 className={ 'text-primary fw-bold' }>Thanks for reaching out!</h1>
          <p className={'lead'}>I've received your message. I should get back to you soon.</p>
          <a href="/" className="btn btn-primary rounded-pill text-light">
            <FaUndoAlt className={ 'me-1' } /> Return to site
          </a>
        </div>
      </div>
    </div>
  )
}
