import React from 'react';
import Certifications from './Certifications';
import Education from './Education';

export default function Experience() {
  return (
    <section id="experience">
      <Education />
      <Certifications />
    </section>
  )
}
