import React from 'react'
import { me } from '../data/AboutMe';
import { analyticsEvent } from '../services/firebase/analytics';
import { Link } from 'react-router-dom'

export default function Hero() {
  return (
    <div className="container col-xxl-8 px-4 pt-5">
      <div className="row flex-lg-row-reverse justify-content-center align-items-center g-5 py-5">
        <div className="col-10 col-sm-8 col-lg-6">
          <div className="responsive-img">
            <img
              src={  me.picture }
              className="d-block mx-lg-auto img-fluid shadow-lg rounded-circle border border-5 border-primary"
              alt={ me.name }
              width="400"
              height="400"
              loading="lazy"
              onClick={() => analyticsEvent('Someone clicked on my avatar')}
            />
          </div>
        </div>
        <div className="col-lg-6 text-center text-lg-start">
          <h1 className="display-4 fw-bold lh-1 mb-3">{ me.name }</h1>
          <h2 className="display-7 fw-bold lh-1 mb-3 text-primary">{ me.position }</h2>
          <p className="lead">{ me.tagline }</p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-center justify-content-lg-start">
            <Link
              type="button"
              className="btn btn-primary text-white btn-lg px-4 me-md-2"
              to={ me.resume }
              loading="lazy"
              download={ true }
              onClick={() => analyticsEvent('Someone downloaded my resume')}
            >
              Resume
            </Link>
            <Link
              type="button"
              className="btn btn-outline-secondary btn-lg px-4"
              to="/hello"
              onClick={() => analyticsEvent('Someone is trying to contact me')}
            >
              Get In Touch
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
