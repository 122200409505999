
export const educationData = [
  {
    major: "Software Engineering",
    school: "Universidad Estatal de Sonora",
    startDate: "Aug 2017",
    endDate: "May 2021",
    summary: `Former member of PASA (Programa de Apoyo y Seguimiento Académico) as a mentor to other students. Being a Software Engineering student allowed me to learn multiple skills that I was able to share with everyone enrolled in the PASA Program, helping them to improve their grades.`
  },
  {
    major: "Communication Sciences",
    school: "Universidad de Sonora",
    startDate: "Aug 2013",
    endDate: "Dec 2015",
    summary: `I wasn't able to get my degree back then, and I had to switch career after 5 out of 9 semesters. From here, I got my passion for film features, photography and pop culture. I wanted to be a film director and screenplay writer.`
  }
]

export const certificationData = [
  {
    name: "Diplomado en Desarrollo Web y Móviles utilizando Angular e IONIC",
    issuedBy: "Universidad Estatal de Sonora",
    issueDate: "August 2021",
    url: "/",
    skills: ['Angular', 'IONIC', 'Node.js', 'Bootstrap', 'TypeScript', 'Firestore Database', 'Realtime Database', 'Hosting'],
    summary: `I learn how to implement text interpolation, pipes, property bindings, event and two-way bindings. Used different directives for components (directives with a template), attributes (to listen and modify behavior of other HTML elements, attributes, properties, and components) and structure (change the DOM layout by managing DOM elements) to add additional behavior to forms, list, styles and other elements. Dependency injection for services in component's constructor and other services; routing to navigate between pages or tabs. Also, we used Firebase Realtime Database and Firestore to interact with No-SQL databases. And finally, deployed our apps using Firebase Hosting. Working with IONIC, I also learned how to prepare for release by signing our app and generate APK package ready to upload to the Play Console.`,
  },
  {
    name: "APIs and Microservices",
    issuedBy: "freeCodeCamp",
    issueDate: "July 2021",
    url: "https://www.freecodecamp.org/certification/nadiemedicejose/apis-and-microservices",
    skills: ['Node.js', 'npm', 'Express', 'MongoDB', 'HTTP'],
    summary: `Wrote back end with Node.js, npm (Node Package Manager), the Express framework, and MongoDB (non-relational or "NoSQL" database that stores JSON documents). Working with npm and the package.json helped me to understand how to manage project dependencies. Also, I used some built-in modules from Node.js to work with HTTP and the request/response cycle and the Express framework to create a server and handle routing for applications.`,
  },
  {
    name: "Front End Libraries",
    issuedBy: "freeCodeCamp",
    issueDate: "June 2021",
    url: "https://www.freecodecamp.org/certification/nadiemedicejose/front-end-libraries",
    skills: ['Bootstrap', 'jQuery', 'SASS', 'React', 'Redux'],
    summary: `Style websites quickly applying mobile-first approach using Bootstrap, add logic to CSS styles and extend them with SASS, create powerful Single Page Applications (SPAs) with React and Redux for building reusable, component-driven user interfaces and share data across different components.`,
  },
  {
    name: "Responsive Web Design",
    issuedBy: "freeCodeCamp",
    issueDate: "April 2021",
    url: "https://www.freecodecamp.org/certification/nadiemedicejose/responsive-web-design",
    skills: ['HTML', 'CSS', 'Flexbox', 'CSS Grid'],
    summary: `Basics of HTML and CSS, how to apply different elements of Visual Design, best practices for building webpages accesible to everyone and Responsive Web Design Principles to design flexible websites that can respond to different screen sizes, orientation and resolutions with Flexbox and CSS Grid.`,
  },
  {
    name: "JavaScript Algorithms and Data Structures",
    issuedBy: "freeCodeCamp",
    issueDate: "March 2021",
    url: "https://www.freecodecamp.org/certification/nadiemedicejose/javascript-algorithms-and-data-structures",
    skills: ['ES6', 'Regular Expressions', 'OOP', 'Functional Programming'],
    summary: `Fundamentals of JavaScript (variables, arrays, objects, loops, and functions), ES6 (including let and const, arrow functions, classes, promises, and modules), and Regular Expressions. Then, applied that knowledge by creating algorithms to manipulate strings and factorialize numbers. I also learned about Object Oriented Programing (OOP), and Functional Programing (including pure functions, how to avoid mutations, and how to write cleaner code).`,
  },
  {
    name: "Web Applications for Everybody Specialization",
    issuedBy: "Coursera",
    issueDate: "October 2020",
    url: "https://www.coursera.org/account/accomplishments/specialization/SZASWB8RFHB7",
    skills: ['PHP', 'JavaScript', 'jQuery', 'MySQL', 'HTML', 'CSS', 'SQL', 'phpMyAdmin'],
    summary: `Online Specialization authorized by University of Michigan and offered through Coursera after completing each course in the Specialization: "Building Web Applications in PHP", "Introduction to Structured Query Language (SQL)", "Building Database Applications in PHP", "JavaScript, jQuery, and JSON".`,
  },
  {
    name: "JavaScript, jQuery, and JSON",
    issuedBy: "Coursera",
    issueDate: "October 2020",
    url: "https://www.coursera.org/account/accomplishments/verify/7M3XB9F43GJC",
    skills: ['PHP', 'jQuery', 'JavaScript', 'JSON'],
    summary: `I learned how JavaScript supports the Object-Oriented pattern, brief introduction to the jQuery library on how it's used to do in-browser manipulation of the Document Object Model (DOM) and event handling. Also, learned about how JS Object Notation (JSON) is used to exchange data between code running on the server and code running in the browser.`,
  },
  {
    name: "Building Database Applications in PHP",
    issuedBy: "Coursera",
    issueDate: "July 2020",
    url: "https://www.coursera.org/account/accomplishments/verify/F4Q7TWWYPKD7",
    skills: ['PHP', 'CRUD', 'MySQL'],
    summary: `I was introduced to the Object Oriented patterns available in PHP. Learned how to connect to MySQL using the Portable Data Objects (PDO) library, how PHP uses cookies and manages session data, how to avoid double posting data, flash messages implementation, how to use a session to log in users in web apps. I built my first complete application with multiple screens to CRUD our data.`,
  },
  {
    name: "Building Web Applications in PHP",
    issuedBy: "Coursera",
    issueDate: "July 2020",
    url: "https://www.coursera.org/account/accomplishments/verify/99GFHYU5ZKPH",
    skills: ['PHP', 'HTML', 'CSS'],
    summary: `I explored the basic structure of a web application, and how a web browser interacts with a web server. I was introduced to the request/response cycle, including GET/POST/Redirect. Also, an introductory understanding of the basic syntax and data structures of PHP, error handling, and superglobal variables, among other elements.`,
  },
  {
    name: "Introduction to Structured Query Language (SQL)",
    issuedBy: "Coursera",
    issueDate: "July 2020",
    url: "https://www.coursera.org/account/accomplishments/verify/LG227RDMDK2P",
    skills: ['phpMyAdmin', 'MySQL', 'Relational Database', 'SQL'],
    summary: `I set up my own WAMP Server from scratch, and created a MySQL database. Single table queries and the basic syntax of the SQL language, as well as database design with multiple tables, foreign keys, and the JOIN operation. Lastly, I learned to model many-to-many relationships.`,
  }
]
