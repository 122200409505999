import React, { useRef } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { analyticsEvent } from '../services/firebase/analytics'

export default function Contact() {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    console.debug("Email has been sent ✅");
  }

  return (
    <section id="contact" className={ 'container my-5 fw-light' }>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-primary fw-bold pt-4 mb-3">Contact</h1>
          <h2 className={ 'text-secondary' }>Get in touch</h2>
          <p>Feel free to contact me for inquiries, questions, or just to say hi! 👋🏽</p>
        </div>

        <form ref={form} onSubmit={sendEmail} className="col-12 col-md-9 col-lg-8 container">
          <div className="mb-3">
            <label className={ 'form-label' } id="label-name" htmlFor="from_name">Name</label>
            <input className={ 'form-control' } id="from_name" type="text" name="from_name" placeholder="Your name" required />
          </div>

          <div className="mb-3">
            <label className={ 'form-label' } id="label-email" htmlFor="reply_to">Email</label>
            <input className={ 'form-control' } id="reply_to" type="email" name="reply_to" placeholder="Email address" required />
            <input id="to_name" type="hidden" name="to_name" value='Jose Estrada' />
          </div>

          <div className="mb-3">
            <label className={ 'form-label' } id="label-message" htmlFor="message">Message</label>
            <textarea className={ 'form-control' } id="message" name="message" rows="3" placeholder="I'd like to chat about..." required />
          </div>

          <div className="mb-3 text-center">
            <button type="submit" className="btn btn-primary text-light" onClick={() => {
              analyticsEvent('Someone sent me a Message');
            }}>
              <FaPaperPlane className={ 'me-1' } /> Send message
            </button>
          </div>
        </form>
      </div>

      <div className="col-12">
        <h2 className={ 'text-secondary' }>Other ways to reach me</h2>
        <p>If you'd prefer to contact me via social media, please feel free to direct message me on any of the below platforms. 👇🏽</p>
      </div>
    </section>
  )
}
