export const FeaturetteContainer = ({ children }) => {
    return (
        <div className="row featurette align-items-center">
            {children}
        </div>
    );
};


const FeaturetteHeading = ({ children }) => {
    return (
        <h2 className="featurette-heading">
            {children}
        </h2>
    );
}

const FeaturetteLead = ({ children }) => {
    return (
        <div className="lead">
            {children}
        </div>
    );
}

const FeaturetteDivider = () => {
    return (
        <hr className={ 'featurette-divider my-5 text-secondary' } />
    );
}

export const Featurette = {
    Container: FeaturetteContainer,
    Heading: FeaturetteHeading,
    Divider: FeaturetteDivider,
    Lead: FeaturetteLead,
}
