import React from 'react';
import { educationData } from '../data/EducationData';

export default function Education() {
  return (
    <div className="experience">
      <h3 className={ 'text-primary fw-bold mt-5 mb-4' }>Education</h3>
      {
        educationData.map(item => (
          <div className="major" key={item.major}>
            <div className="major-info">
              <h4>{ item.major }</h4>
              <p className="bold">🎓 { item.school }</p>
              <span>📆 { item.startDate } — { item.endDate }</span>
            </div>
            <p className="fw-light">{ item.summary }</p>
          </div>
        ))
      }
    </div>
  )
}
