import React, { Fragment } from 'react';

export default function Card(props) {
  return (
    <div className="card shadow-sm">
      <div className="card-header bg-white text-center text-dark display-5 py-3 border-bottom-0">
        <i className={ 'text-primary' }>{ props.icon }</i>
        <h3 className={ 'mt-3' }>{ props.header }</h3>
      </div>
      <div className="card-body text-center fw-light">
        { props.desc }
      </div>
      <div className={ 'card-footer text-center bg-white py-3 border-top-0' }>
        <div className="row">
          {
            (props.h2)
              ? <div className="col-12">
                <div className={ 'fs-5' }>{ props.h2 }</div>
                <div className={ 'd-flex flex-wrap justify-content-center gap-2 py-3' }>
                  {
                    props.desc2.map((tool, index) => (
                      <span className={ 'badge bg-primary bg-opacity-10 text-primary fw-normal' } key={ index }>{tool}</span>
                    ))
                  }
                </div>
              </div>
              : <Fragment></Fragment>
          }

          {
            (props.h3)
              ? <div className="col-12">
                <div className={ 'fs-5' }>{ props.h3 }</div>
                <p className={ 'd-flex flex-wrap justify-content-center gap-2 my-3' }>
                  {
                    props.desc3.map((tool, index) => (
                      <span className={ 'badge bg-primary bg-opacity-10 text-primary fw-normal' } key={ index }>{tool}</span>
                    ))
                  }
                </p>
              </div>
              : <Fragment></Fragment>
          }
        </div>
      </div>
    </div>
  )
}
