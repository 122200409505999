import React, { Fragment } from 'react';
import { analyticsEvent } from '../services/firebase/analytics';
import { FaCode, FaExternalLinkAlt } from 'react-icons/fa';

export default function ProjectCard(props) {
  return (
    <div className="card shadow-sm" onClick={() => {
      analyticsEvent('Clicked project ' + props.projectName);
    }}>
      <img className={ 'card-img-top' } src={ props.image } alt={ props.projectName }></img>
      <div className="card-body">
        <h5 className="card-title">{ props.projectName }</h5>
        <p className="d-flex flex-wrap justify-content-start gap-2">
          {
            props.tools.map(tool => (
              <span className={ 'badge bg-primary bg-opacity-10 text-primary fw-normal' } key={tool}>{tool}</span>
            ))
          }
        </p>
        <div className="card-text fw-light">{ props.description }</div>
      </div>
      {
        (props.repo || props.demo)
        ? <div className="card-footer bg-white border-top-0 mt-0 pt-0 mb-3 d-flex justify-content-center gap-2">
            {
              (props.repo)
                ? <a className={ 'btn btn-primary text-light' } href={ props.repo } target="_blank" rel="noreferrer" onClick={() => {
                  analyticsEvent(`Someone clicked on my ${ props.projectName } project's Repo`);
                }}>
                  <FaCode className={ 'me-1' } /> Repo
                </a>
                : <Fragment></Fragment>
            }

            {
              (props.demo)
                ? <a className={ 'btn btn-secondary text-light' } href={ props.demo } target="_blank" rel="noreferrer" onClick={() => {
                  analyticsEvent(`Someone clicked on my ${ props.projectName } project's Demo`);
                }}>
                  <FaExternalLinkAlt className={ 'me-1' } /> Demo
                </a>
                : <Fragment></Fragment>
            }
          </div>
        : <Fragment></Fragment>
      }
    </div>
  )
}
