import React from 'react';
import { certificationData } from '../data/EducationData';
import { FaCalendar, FaExternalLinkAlt } from 'react-icons/fa';

export default function Certifications() {
  return (
    <div className="accordion" id="certifications">
      <h3 className={ 'text-primary fw-bold mt-5 mb-4' }>Certifications</h3>
      {
        certificationData.map((item, key) => (
          <div className="accordion-item" key={ key }>
            <h2 className="accordion-header" id={ `heading-${ key }` }>
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={ `#collapse-${ key }` }
                aria-expanded="false" aria-controls={ `#collapse-${ key }` }>
                { item.name }
              </button>
            </h2>
            <div id={ `collapse-${ key }` } className="accordion-collapse collapse" aria-labelledby={ `heading-${ key }` }
              data-bs-parent="#certifications">
              <div className="accordion-body fw-light">
                <div className="d-flex justify-content-between justify-content-md-start gap-md-2 mb-3 text-primary">
                  <div className={ "badge bg-transparent p-2 fw-normal text-secondary" }><FaCalendar /> { item.issueDate }</div>
                  <a className={ "badge bg-primary p-2 fw-normal text-light text-decoration-none" } href={ item.url }><FaExternalLinkAlt className={ 'me-1' } /> { item.issuedBy }</a>
                </div>
                <span className={ 'd-block text-justify' }>{ item.summary }</span>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}
