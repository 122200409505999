import { images } from '../assets/project-pictures';

export const projectsData = [
  {
    projectName: 'LANIX',
    description: `We migrated from a corporate website into an E-Commerce Store. I actively collaborate in redesign, building components, testing PayPal integration, and more.`,
    tools: ['PHP', 'Laravel', 'jQuery', 'AJAX', 'MySQL', 'Eloquent', 'Bootstrap', 'PayPal APIs'],
    demo: 'https://lanix.com',
    image: images.lanix
  },
  {
    projectName: 'Request Header Parser',
    description: `Microservice to GET a JSON object with your IP address, preferred language, and software.`,
    tools: ['JavaScript', 'Express', 'API', 'JSON'],
    repo: 'https://github.com/nadiemedicejose/header-parser',
    demo: 'https://headerparser.nadiemedicejose.repl.co',
    image: images.headerparser
  },
  {
    projectName: 'Timestamp',
    description: `Microservice to GET a JSON object with a timestamp of the input date in UNIX (milliseconds) and UTC (string) formats.`,
    tools: ['JavaScript', 'Express', 'API', 'JSON'],
    repo: 'https://github.com/nadiemedicejose/timestamp',
    demo: 'https://timestamp.nadiemedicejose.repl.co',
    image: images.timestamp
  },
  {
    projectName: 'File Metadata',
    description: `Microservice to upload a file, and GET a JSON response with the file name, type and size in bytes.`,
    tools: ['JavaScript', 'Express', 'API', 'JSON'],
    repo: 'https://github.com/nadiemedicejose/file-metadata',
    demo: 'https://filemetadata.nadiemedicejose.repl.co/',
    image: images.filemetadata
  },
  {
    projectName: 'URL Shortener',
    description: `Microservice to POST a URL and get a JSON response with original_url and short_url props. When you visit /api/shorturl/<short_url>, you will be redirected to the original URL.`,
    tools: ['JavaScript', 'Express', 'API', 'JSON'],
    repo: 'https://github.com/nadiemedicejose/url-shortener',
    demo: 'https://url-shortener.nadiemedicejose.repl.co/',
    image: images.urlshortener
  },
  {
    projectName: '25 + 5 Clock',
    description: 'Pomodoro technique clock for sessions of 25 minutes and breaks of 5 minutes. User can change both lengths and adjust to their needs.',
    tools: ['React', 'SASS', 'GH Pages', 'VS Code'],
    repo: 'https://github.com/nadiemedicejose/25-5-clock',
    demo: 'https://nadiemedicejose.github.io/25-5-clock/',
    image: images.clock
  },
  {
    projectName: 'JavaScript Calculator',
    description: 'Basic calculator using formula logic to compute the 4 primary mathematical operators: add, subtract, multiply and divide.',
    tools: ['React', 'SASS', 'GH Pages', 'VS Code'],
    repo: 'https://github.com/nadiemedicejose/calculator',
    demo: 'https://nadiemedicejose.github.io/calculator/',
    image: images.calculator
  },
  {
    projectName: 'Drum Machine',
    description: 'Implementation of HTML5 audio tag triggered on drum pad click or key event.',
    tools: ['HTML5', 'React', 'Hooks', 'SASS', 'GH Pages', 'VS Code'],
    repo: 'https://github.com/nadiemedicejose/drum-machine',
    demo: 'https://nadiemedicejose.github.io/drum-machine/',
    image: images.drumMachine
  },
  {
    projectName: 'Markdown Previewer',
    description: 'GitHub flavored markdown editor to render text as HTML in the preview panel as you type.',
    tools: ['React', 'SASS', 'Marked', 'GH Pages', 'VS Code'],
    repo: 'https://github.com/nadiemedicejose/markdown-previewer',
    demo: 'https://nadiemedicejose.github.io/markdown-previewer/',
    image: images.markdownPreviewer
  },
  {
    projectName: 'Random Quote Machine',
    description: 'Display a random quote and author based on button click event and tweet the current quote.',
    tools: ['React', 'SASS', 'GH Pages', 'VS Code'],
    repo: 'https://github.com/nadiemedicejose/randomquotemachine',
    demo: 'https://nadiemedicejose.github.io/randomquotemachine/',
    image: images.randomQuoteMachine
  },
  {
    projectName: 'ECOmmunity',
    description: 'Android app for local communities focused on environmental issues. People can report polluted places, join different groups and help community cleaning days.',
    tools: ['Figma', 'VS Code', 'Flutter', 'Android'],
    repo: 'https://github.com/nadiemedicejose/ecommunity',
    image: images.ecommunity
  },
  {
    projectName: "Dental Office",
    description: "Desktop app for a dental office to create, read, update and delete patients, appointments, dentists and specialties.",
    tools: ['C#', 'LINQ to SQL', 'WinForms', 'Visual Studio'],
    repo: "https://github.com/nadiemedicejose/Consultorio",
    demo: "https://nadiemedicejose.medium.com/consultorio-dental-ba1e304aaeb6",
    image: images.dentalOffice
  },
  {
    projectName: "School",
    description: "Desktop app for school management, focused on student enrollment; teacher, career and subject assignation and get reports of enrolled students by categories.",
    tools: ['C#', 'LINQ to SQL', 'SQL Server', 'WinForms', 'Visual Studio', 'Reporting Services'],
    repo: "https://github.com/nadiemedicejose/Escuela",
    demo: "https://youtu.be/CNGN_-ibX6E",
    image: images.school
  },
  {
    projectName: "Loan Simulator",
    description: "Web application that helps you to calculate the amortization table based on the date, amount, payment period, interest rate and term.",
    tools: ['HTML', 'CSS', 'JavaScript', 'VS Code'],
    repo: "https://github.com/nadiemedicejose/simulador-prestamos",
    demo: "https://joseestrada-simulador-prestamos.netlify.app/",
    image: images.loanSimulator,
  },
  {
    projectName: 'Reparaciones HMO',
    description: 'Design for a computer and mobiles fix shop administration. User can keep track of the recent fixes to custumer devices, manage contact and employees lists, and get reports based on devices, clients or just dates.',
    tools: ['Sketch', 'Prototyping'],
    image: images.reparacionesHmo,
  },
  {
    projectName: 'UES Store',
    description: 'Design for an e-commerce to sell custom clothing. Users upload their own design, adjust color, sizes and location.',
    tools: ['Sketch', 'Prototyping'],
    image: images.uesStore,
  },
]
